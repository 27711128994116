<template>
  <div v-loading="loading" class="container">
    <Box class="topic">
      <div slot="header"></div>
      <h4 class="title">{{ detail.title }}</h4>
      <div class="label">{{ $t('7bda0bf') }}：</div>
      <p class="info">
        {{ detail.content }}
      </p>
      <div class="footer">
        {{ detail.consultant }}
        <i></i>
        {{ types[detail.type] }}
        {{ detail.consultingType }}
        <i></i>
        {{ detail.createTime }}
        <i></i>
        <span v-if="isReplyed" class="green">{{ $t('503a304') }}</span>
        <span v-else>{{ $t('35a2e8d') }}</span>
      </div>
    </Box>
    <Box :title="$t('7acb87e')" class="reply">
      <template v-if="isReplyed">
        <div class="user">
          <div
            class="avatar"
            :style="{ backgroundImage: 'url(' + detail.picture + ')' }"
          ></div>
          <div class="info">
            <div class="nick">{{ detail.replier }}</div>
            <div class="num">{{ $t('1c515a9') }}：{{ detail.count }}</div>
          </div>
        </div>
        <p class="content" v-html="detail.reply">
        </p>
        <div class="footer">{{ $t('b4d7f61') }}：{{ detail.replyTime }}</div>
      </template>
      <div v-else class="no-reply">暂无回复</div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Breadcrumb from '@/components/Breadcrumb'
import Api from '@/api/content.consult'

export default {
  components: {
    Box,
    Breadcrumb,
  },
  data() {
    return {
      loading: false,
      detail: {},
    }
  },
  computed: {
    isReplyed: function() {
      return this.detail.replyTime
    },
    types() {
      return {
        LAW_SERVICE: this.$t('a24979f'),
        COMPANY_ENTER: this.$t('4b12ad8'),
        PLATFORM_USE: this.$t('59f6aea'),
        OTHER: this.$t('dbb3a5c'),
      }
    },
    nav() {
      return [
        { name: this.$t('e7fc30f'), path: '/business/consult' },
        { name: this.$t('839c66b'), path: '' },
      ]
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.loading = true
      const id = this.$route.query.id
      Api.getDetail(id)
        .then((res) => {
          console.log(res)
          this.detail = res
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 1200px;
  margin: 0 auto 30px;
}

.topic {
  .title {
    margin-top: -30px;
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 2px dashed #f3f3f3;
    font-size: 20px;
    color: #333333;
    font-weight: 500;
  }

  .label {
    padding: 30px 0 20px;
    font-size: 16px;
    color: #333333;
    line-height: 1;
  }

  .info {
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    padding-bottom: 30px;
    word-break: break-all;
  }

  .footer {
    border-top: 2px dashed #f3f3f3;
    margin-bottom: -30px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    color: #999999;

    .green {
      color: #51c518;
    }

    i {
      margin: 0 10px;
      &::after {
        content: '|';
      }
    }
  }
}

.reply {
  .user {
    display: flex;
    padding-bottom: 26px;

    .avatar {
      margin-right: 30px;
      width: 86px;
      height: 86px;
      border-radius: 50%;
      background: #edf6fd no-repeat center;
      background-size: cover;
      overflow: hidden;
    }

    .info {
      display: flex;
      justify-content: center;
      flex-flow: column;

      .nick {
        font-size: 18px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .num {
        font-size: 14px;
        color: #999999;
      }
    }
  }

  .content {
    padding-left: 30 + 86px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    padding-bottom: 30px;
    word-break: break-all;
  }

  .footer {
    border-top: 2px dashed #f3f3f3;
    margin-bottom: -30px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    color: #999999;

    .green {
      color: #51c518;
    }
  }

  .no-reply {
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #999999;
  }
}
</style>
